// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// Components
import Header from '../public/Header';
import Loader from '../shared/Loader';

// Actions
import truckActions from '../../actions/truckActions';

// Selectors
import { getCityConfig, getDeletionStatus } from '../../selectors';

import RouteUtils from '../../modules/routeUtils';

class DeletionStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletionStatus: false
    }
  }

  componentDidMount() {
    const params = RouteUtils.getQueryParams(this.props.location);
    this.props.fetchDeletionStatus({ id: params.id }).then((response) => {
      this.setState({ deletionStatus: response.deletionStatus });
    });
  }

  render() {
    const { cityConfig } = this.props;
    const { deletionStatus } = this.state;

    return (
      <div>
        <Header
          history={this.props.history}
          location={this.props.location}
        />
        <div className="Box Box--white u-blockCenter u-limitWidth30 u-mt2">
          <h2 className='u-mb2'>Data Deletion Status</h2>
          { deletionStatus && 
            <p>{ deletionStatus }</p>
          }
          { !deletionStatus &&
            <div className='u-mb1'>
              <Loader show={true} />
            </div>
          }
          <p>
            Questions? Email us at<br/>
            <a href={"mailto:" + cityConfig.support_email}>{cityConfig.support_email}</a>
          </p>
          <Link className="Button u-mt1" to={'/'}>
            Return Home
          </Link>
        </div>
      </div>
    );
  }
}

DeletionStatus.propTypes = {
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDeletionStatus(options) {
      return dispatch(truckActions.fetchDeletionStatus(options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeletionStatus));
